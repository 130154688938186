<template>
    <div style="height: 100%">
        <div style="display: flex;justify-content: space-between;height: 100%">
            <div style="width: 450px;height: 100%">
                <div class="padding-sm" style="display: flex;justify-content: space-between;align-items: center">
                    <div>销售组管理</div><div><el-button v-if="role === 1" type="primary" size="mini" @click="createdTopGrpup">创建组</el-button></div>
                </div>

                <div class="padding-sm">
                    <el-tree
                            style=""
                            :data="groupData"
                            @node-click="groupDetail"
                            :highlight-current="true"
                            :expand-on-click-node="false"
                            node-key="id"
                            :default-expand-all="true">
                        <div class="" slot-scope="{ node, data }" style="width:100%;padding:5px">
                            <div style="display: flex;justify-content: space-between;align-items: center">
                                <div style="display: flex;">
                                    <span>{{ data.name }}</span>
                                    <span style="margin-left: 30px; display: flex;justify-content: center;align-items: center">
                                        <el-avatar :size="18" :src="data.leader_name_avatar_url"></el-avatar>
                                        <i style="margin-left: 5px">{{data.leader_name}}</i>
                                    </span>
                                </div>
                                <el-dropdown trigger="click" @command="handleCommand" :hide-on-click="false">
                                  <span class="el-dropdown-link"  @click.stop="">
                                    <i style="display: inline-block;padding: 8px" class="">...</i>
                                  </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-if="role === 1" :command="{fun:'addGroup',data:data}">
                                            添加子组
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="role === 1" :command="{fun:'editGroup',data:data}">
                                            编辑组
                                        </el-dropdown-item>
                                            <el-popconfirm
                                                    v-if="role === 1"
                                                    style="padding:0 10px"
                                                    confirm-button-text='确定'
                                                    cancel-button-text='取消'
                                                    icon="el-icon-info"
                                                    icon-color="red"
                                                    title="删除后无法恢复，确认删除吗？"
                                                    @confirm="delGroup(node,data)"
                                            >
                                                <el-dropdown-item slot="reference" :command="{fun:''}">
                                                    删除组
                                                </el-dropdown-item>
                                            </el-popconfirm>

                                        <el-dropdown-item :command="{fun:'addGroupUsers',data:data}">
                                            添加组员
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>

                            </div>
                        </div>
                    </el-tree>
                </div>
            </div>
            <div class="padding-sm" style="width: calc(100% - 450px);border-left: 1px solid #f1f1f1;">
                <div  style="padding: 10px 0;">组员列表</div>
                <div style="margin-top: 10px">
                    <el-table
                            v-loading="tableLoading"
                            :header-cell-style="{'background':'#f1f1f1'}"
                            size="mini"
                            :data="users"
                            style="width: 100%">
                        <el-table-column
                                prop="date"
                                label="ID">
                            <template slot-scope="scope">
                                <span >{{ scope.row.user_id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                label="姓名">
                            <template slot-scope="scope">
                                <span style="display: flex;align-items: center">
                                    <el-avatar :size="18" :src="scope.row.user_name_avatar_url"></el-avatar>
                                    <i style="margin-left: 5px">{{scope.row.user_name}}</i>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                width="100">
                            <template slot-scope="scope">
                                <el-button @click="delGroupUser(scope.row)" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>


        <el-dialog
                append-to-body
                :close-on-click-modal="false"
                :show-close="false"
                title="创建销售组"
                :visible.sync="dialogVisible"
                width="50%">
            <div>
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="组名">
                        <el-input v-model="form.name" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="上级组">
                        <el-select size="mini" style="width: 100%" v-model="form.parentId" placeholder="选择上级组">
                            <el-option
                                    v-for="(item,index) in groups"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="组长">
                        <select-user :role="5" :selectUserId="form.leaderId" @onSelect="selectGroupLeaderId"></select-user>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
            <el-button type="primary" @click="createSalesGroup" :loading="submitBtnLoading" size="mini">确 定</el-button>
          </span>
        </el-dialog>
        <add-group-users :isShow="isShow" :groupId="groupId" :groupName="groupName" @hide="hide"></add-group-users>
    </div>
</template>

<script>
    import selectUser from "../../../components/selectUser";
    import {mapActions} from "vuex";
    import AddGroupUsers from "./addGroupUsers";
    export default {
        name:"list",
        components:{AddGroupUsers, selectUser },
        data() {
            return {
                role:0,
                users:[],
                tableLoading:false,
                isShow:false,
                groupName:'',
                groupId:0,
                groups:[
                    {
                        id:0,
                        name:'顶级组'
                    }
                ],
                form:{
                    id:'',
                    name:'',
                    parentId:0,
                    leaderId:0
                },
                currentGroupId:0,
                submitBtnLoading:false,
                dialogVisible: false,
                groupData: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                groupList:[]
            };
        },
        methods: {
            ...mapActions('sales',['setSalesGroup','getSalesGroup','delSalesGroup','getSalesGroupUsers','delSalesGroupUser']),
            selectGroupLeaderId(userId){
                this.form.leaderId = userId
            },
            handleCommand(e){
                switch (e.fun) {
                    case 'addGroup':
                        this.addGroup(e.data)
                        break
                    case 'editGroup':
                        this.editGroup(e.data)
                        break
                    case 'addGroupUsers':
                        this.addGroupUsers(e.data)
                        break
                }
            },
            groupDetail(e){
                this.currentGroupId = e.id
                this.tableLoading = true
                this.getSalesGroupUsers({id:e.id}).then(res => {
                    this.users = res.data
                    console.log(res)
                }).finally(() => {
                    this.tableLoading = false
                })

            },
            createdTopGrpup(){
                this.dialogVisible = true
                this.form.parentId = 0
            },
            createSalesGroup(){
                this.submitBtnLoading = true
                this.setSalesGroup(this.form).then(res => {
                    if(res.ret === 0 ){
                        this.$message.success('创建成功')
                        this.dialogVisible = false
                        this.getSalesGroupList()
                    }else
                        this.$message.error('创建失败')
                }).finally(() => {
                    this.submitBtnLoading = false
                })
            },
            async getSalesGroupList()
            {
                let groups = await this.getSalesGroup()
                this.groupList = groups.data
                this.groups = this.groups.concat(this.groupList)
                this.groupData = this.getTree(groups.data,0,[])
            },
            getTree(list,pid,data){
                //获取所有一级
                for(let item of list){
                    if(item.parent_id === pid){
                        data.push(item);
                    }
                }
                //获取子级
                for(let i of data){
                    i.children = [];
                    this.getTree(list,i.id,i.children);//递归调用
                    if(i.children.length == 0){
                        delete i.children;
                    }
                }
                return data;
            },
            addGroup(e){
                this.dialogVisible = true
                this.form.parentId = e.id
            },
            editGroup(e){
                this.dialogVisible = true
                this.form = {
                    id:e.id,
                    parentId:e.parent_id,
                    name:e.name,
                    leaderId:e.leader_id
                }
            },
            delGroup(node,data){
                this.delSalesGroup({id:data.id}).then(res => {
                    if(res.ret === 0){
                        this.$message.success('删除成功')
                        this.getSalesGroupList()
                    }
                })
            },
            addGroupUsers(e){
                this.groupName = e.name
                this.groupId = e.id
                this.isShow = true
            },
            hide(){
                this.isShow = false
            },
            delGroupUser(row){
                this.delSalesGroupUser({id:row.id}).then(res => {
                    if(res.ret === 0){
                        this.$message.success('删除成功')
                        this.groupDetail({id:this.currentGroupId})
                    }
                })
            }
        },
        mounted() {
            this.role = Number(this.$cookies.get('role'))
            this.getSalesGroupList()
        }
    };
</script>

<style >
    .el-tree-node__content{
        height: auto;
    }
</style>